<template>
    <div>
        <Quote title="Video-darslar bepul bo’lishi shart!" class="text-center"/>
        <GoBack next="Izoh qoldiring"/>
        <div class="row justify-content-center mt-5 zoomIn">
            <div class="col-11 col-sm-10 col-md-6">
                <form v-if="getMyCourses.length > 0" id="course-type " @submit.prevent="addComment">
                    <div class="row div6 justify-content-end">
                        <div class="col-12 mb-3">
                            <label for="exampleInputId2" class="form-label"></label>
                            <select
                                v-model="comment.course"
                                class="form-select form-control p-10"
                                id="exampleInputId2"
                                required
                            >
                                <option value="" disabled selected hidden>Kursni tanlang...</option>
                                <option
                                    v-for="course in getMyCourses"
                                    :key="course.id"
                                    :label="course.course.name"
                                    :value="'/api/courses/' + course.course.id"
                                />
                            </select>
                        </div>
                    </div>

                    <div class="col-12 d-flex justify-content-end mb-1">
                        <div class="col-12 col-sm-12 col-md-7">
                            <span
                                v-if="getImage"
                                class="w-100 text-lg-end d-inline-block pt-1 text-primary text-truncate"
                            >
                            {{ getImage.contentUrl.slice(21, 100) }}
                        </span>
                            <input
                                id="image"
                                style="display: none"
                                type="file"
                                accept="image/*"
                                @change="setImage($event)"
                            >
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mb-3">
                        <label
                            for="image"
                            class="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-4 align-content-center image"
                        >
                            Rasm yuklang
                        </label>
                    </div>

                    <div class="form-group mb-3">
                        <div class="input-group">
                            <textarea
                                v-model="comment.text"
                                cols="10" rows="10"
                                class="form-control area pl-3 pt-3"
                                placeholder="Izoh qoldiring"
                                aria-label="Width textarea"
                                required
                            >
                            </textarea>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-4">
                        <div class="mb-3 col-12 col-md-5 col-lg-4 col-xl-3">
                            <button-component
                                :disabled="!comment.text.length"
                            >
                                Saqlash
                            </button-component>
                        </div>
                    </div>

                    <div>
                        <b-modal ref="modal-error" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Izohingiz tasdiqlash uchun moderatorga yuborildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>

                <div v-else>
                    <p class="comment-text">Hozirgi vaqtda sizning ro'yxatdan o'tgan kurslarinigiz mavjud emas.
                    </p>
                </div>
            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions, mapGetters} from "vuex";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
    name: "Course_type",
    components: {ButtonComponent, GoBack, Quote},
    data() {
        return {
            courseType: {
                name: "",
                price: ''
            },
            media: {
                file: null,
            },
            comment: {
                course: '',
                image: '',
                text: '',
            },
            show: true
        }
    },
    computed: {
        ...mapGetters(['getImage', 'getMyCourses'])
    },
    methods: {
        ...mapActions(['pushImage', 'pushComment', 'fetchMyCourses']),

        setImage(event) {
            this.media.file = event.target.files[0];
            const data = new FormData();
            data.append('file', this.media.file);
            this.pushImage(data)
                .then(() => {
                    this.comment.image = this.getImage['@id']
                })
        },
        addComment() {
            if (this.comment.course && this.comment.text) {
                if (this.comment.image) {
                    this.pushComment(this.comment)
                } else {
                    this.pushComment({
                        course: this.comment.course,
                        text: this.comment.text
                    })
                }
                this.$refs['modal-error'].show()
                setTimeout(() => {
                    this.hideErrorModal()
                    this.$router.push('/cabinet')
                }, 3000)
            }
        },
        hideErrorModal() {
            this.$refs['modal-error'].hide()
        },
        errorModal() {
            this.$refs['modal-unsuccess'].hide()
        }
    },
    mounted() {
        this.show = true
        this.fetchMyCourses()
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
}

.zoomIn {
    animation: zoomIn;
    animation-duration: 1s;
}

.comment-text {
    font-size: 24px;
    text-align: center;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

textarea {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

::-webkit-input-placeholder {
    padding: 10px;
}

select {
    padding-left: 10px;
    line-height: 32px;
    text-indent: 4px;
    cursor: pointer;
}

.image {
    height: 37px;
    text-align: center;
    padding: 5px 0;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
    cursor: pointer;
    color: #FFFFFF;
}

.image:hover {
    background-color: #990098 !important;
    border-color: #990098;
}

#course-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    width: auto;
    height: 174px;
    left: 259.5px;
    top: 111px;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.justify-content-end .btn-primary {
    text-align: center;
    padding: 5px 60px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F !important;
    outline: none;
    border-color: #80007F;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098;
}

</style>
