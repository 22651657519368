import { render, staticRenderFns } from "./CourseLeftComment.vue?vue&type=template&id=739baf60&scoped=true&"
import script from "./CourseLeftComment.vue?vue&type=script&lang=js&"
export * from "./CourseLeftComment.vue?vue&type=script&lang=js&"
import style0 from "./CourseLeftComment.vue?vue&type=style&index=0&id=739baf60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "739baf60",
  null
  
)

export default component.exports